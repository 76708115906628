import * as React from "react"
import { graphql, PageProps, HeadFC } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import Fallback from "../components/fallback"
import { SEO } from "../components/seo"

interface QueryReturn {
  aboutPage: {
    id: string
    title: string
    description: string
    image: { id: string; url: string }
    blocks: sections.HomepageBlock[]
  }
}

const About: React.FC<PageProps<QueryReturn>> = ({ data }) => {
  const { aboutPage } = data

  return (
    <Layout {...aboutPage}>
      {aboutPage.blocks.map((block) => {
        const { id, blocktype, ...componentProps } = block
        const Component = sections[blocktype] || Fallback
        return <Component key={id} {...(componentProps as any)} />
      })}
    </Layout>
  )
}

export default About

export const Head: HeadFC<QueryReturn> = ({ data: { aboutPage }, location }) => (
  <SEO title={aboutPage.title} description={aboutPage.description} pathname={location.pathname} />
)

export const query = graphql`
  {
    aboutPage {
      id
      title
      description
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        ...AboutHeroContent
      }
    }
  }
`